import React from 'react'
import Footer from './Footer'
import Header from './Header'

function Services() {
  return (
    <>
    <Header title={"Our Services"} subtitle={"Services"} />
    <Footer />
    
    </>
  )
}

export default Services