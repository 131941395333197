import logo from './logo.svg';
import './App.css';
import React, {useState,useEffect} from 'react'

import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Outlet,Navigate, Router,
  } from "react-router-dom";
import Home from './components/Home';
import Services from './components/Services';
import About from './components/About';
import Contact from './components/Contact';
export const PrivateRoute = ({ children}) => {
    const isAuthenticated = true;
        
    if (window.localStorage.getItem("isOfficeUserLoggedIn") == "YES") {
  
      console.log(children)
      return children
    }
      
    return <Navigate to="/" />
  }
function App() {

  return (
    <>
    <BrowserRouter>

<Routes>
<Route exact path="/" element={<Home />}  />
<Route exact path="/Home" element={<Home />}  />
<Route exact path="/Services" element={<Services />}  />
<Route exact path="/About" element={<About />}  />
<Route exact path="/Contact" element={<Contact />}  />

</Routes>



</BrowserRouter>  
    </>
  );
}
export default App;
