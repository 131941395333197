import React, { useState,useEffect } from 'react'
function Header({title,subtitle}) {
    
    const [titleLink,setTitleLink] = useState("/"+subtitle)
  
    return (

    <>
     {/* Top bar starts */}
     <div className="container-fluid bg-dark px-5 d-none d-lg-block">
        <div className="row gx-0">
            <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
                <div className="d-inline-flex align-items-center" style={{height:'45px'}}>
                    <small className="me-3 text-light"><i className="fa fa-phone-alt me-2"></i>+919441158488</small>
                    <small className="text-light"><i className="fa fa-envelope-open me-2"></i>info@cognidots.com</small>
                </div>
            </div>
            <div className="col-lg-4 text-center text-lg-end">
                <div className="d-inline-flex align-items-center" style={{height: '45px'}}>
                    <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i className="fab fa-twitter fw-normal"></i></a>
                    <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                    <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                    <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i className="fab fa-instagram fw-normal"></i></a>
                    <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle" href=""><i className="fab fa-youtube fw-normal"></i></a>
                </div>
            </div>
        </div>
    </div>
    {/* Top bar ends */}

    {/* Navbar starts */}
    <div className="container-fluid position-relative p-0">
        <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
            <a href="/" className="navbar-brand p-0">
                <h1 className="m-0">Cognidots</h1>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="fa fa-bars"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto py-0">
                    <a href="/" className="nav-item nav-link active">Home</a>
                    <a href="/About" className="nav-item nav-link">About</a>
                    <a href="/Services" className="nav-item nav-link">Services</a>
                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Blog</a>
                        <div className="dropdown-menu m-0">
                            <a href="/Blog" className="dropdown-item">Blog Grid</a>
                            <a href="/Detail" className="dropdown-item">Blog Detail</a>
                        </div>
                    </div>
                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                        <div className="dropdown-menu m-0">
                            <a href="/price" className="dropdown-item">Pricing Plan</a>
                            <a href="/feature" className="dropdown-item">Our features</a>
                            <a href="/team" className="dropdown-item">Team Members</a>
                            <a href="/testimonial" className="dropdown-item">Testimonial</a>
                            <a href="/quote" className="dropdown-item">Free Quote</a>
                        </div>
                    </div>
                    <a href="/contact" className="nav-item nav-link">Contact</a>
                </div>
                
            </div>
        </nav>
        <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: '90px'}}>
            <div className="row py-5">
                <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                    <h1 className="display-4 text-white animated zoomIn">{title}</h1>
                    <a href="/home" className="h5 text-white">Home</a>
                    <i class="far fa-circle text-white px-2"></i>
                    <a href={titleLink} className="h5 text-white">{subtitle}</a>
                </div>
            </div>
        </div>
    </div>
    
    {/* Navbar and Carousel ends */}
    </>
  )
}

export default Header