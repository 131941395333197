import React from 'react'
import Footer from './Footer'
import Header from './Header'

function About() {
  return (
    <>
    <Header title={"About Us"} subtitle={"About"} />
    <Footer />
    
    </>
  )
}

export default About